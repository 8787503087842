import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Button, Card, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { Root, RootForm } from './EditClient.style';
import uploadImg from '@/api/customer/uploadImg';
import getStatus from '@/api/productStatus/get'
import getSubStatus from '@/api/productSubStatus/get'
import update from '@/api/customer/update'
import getOne from '@/api/customer/getOne';
import { useSearchParams } from 'react-router-dom';

const EditClient = () => {
  const [customer, setCustomer] = useState(null)
  const [listStatus, setListStatus] = useState([])
  const [listSubStatus, setListSubStatus] = useState([])
  const [error, setError] = useState<null | string>(null)
  const [load, setLoad] = useState(false)
  const [message, setMessage] = useState('')
  const [img, setImg] = useState<any>(null)
  const [imgPreview, setImgPreview] = useState<any>(null)
  const [name, setName] = useState<null | string>(null)
  const [email, setEmail] = useState<null | string>(null)
  const [phone, setPhone] = useState<null | string>(null)
  const [searchParams] = useSearchParams();

  const obtainClient = async () => {
    const id = searchParams.get('id')
    if (!id) {
      return console.log("Falta el id")
    }
    const res = await getOne(id)
    console.log("getOne", res)
    if (!res) {
      return alert('Error al obtener cliente')
    }
    setCustomer(res.data)
    setImgPreview(res.data.img)
    setName(res.data.name)
    setEmail(res.data.email)
    setPhone(res.data.phone)
  }

  useEffect(() => {
    obtainClient()
  }, [])

  const obtainStatus = async () => {
    const res = await getStatus()
    if (!res) {
      return console.log('Error al obtener estados')
    }
    setListStatus(res.data)
  }
  const obtainSubStatus = async () => {
    const res = await getSubStatus()
    if (!res) {
      return console.log('Error al obtener estados')
    }
    setListSubStatus(res.data)
  }

  useEffect(() => {
    obtainStatus()
    obtainSubStatus()
  }, [])

  const editClient = async () => {
    const id = searchParams.get('id')
    setError('')
    let req: any = {
      id,
      name,
      phone,
      email,
    }
    if (img) {
      const res = await uploadImageUrl(img)
      if (!res) { return; }
      req.img = res
    }
    setLoad(true)
    const res = await update(req)
    setLoad(false)
    if (!res) {
      return setError('Error al actualizar cliente')
    }
    setMessage('¡Cliente editado con éxito!')
    empityState()
    await obtainClient()
  }

  const fileInput = useRef(null)
  const empityState = () => {
    fileInput.current.value = ''
    setImg(null)
  }

  const uploadImageUrl = async (objectImage) => {
    setLoad(true)
    const res = await uploadImg(objectImage)
    setLoad(false)
    if (!res) {
      setError('Error al cargar imagen Cliente')
      return null
    }
    if (!res.data) {
      if(res.error) {
        setError(res.error.errorMessage)
        return null
      } else {
        setError('Error al cargar imagen Cliente')
        return null
      }
    }
    return res.data.url
  }

  const onSubmit = () => {
    setError('')
    editClient()
  }


  const uploadFileImg = event => {
    if (event.target.files && event.target.files[0]) {
      const i = event.target.files[0]
      setImg(i)
    }
  }

  return (
    <Root>
      <MenuHeader
        goBack
        right={() => { }}
        title='Editar Cliente'
      />
      <RootForm>
        <Card>
          <Grid style={{
            padding: 20
          }} container spacing={4} alignItems={'center'}>
            <Grid item xs={12}>
              <Typography>Foto Cliente</Typography>
            </Grid>
            <Grid item xs={2}>
              <Avatar
                src={img ? URL.createObjectURL(img) ? URL.createObjectURL(img) : null : imgPreview}
              />
            </Grid>
            <Grid item xs={10}>
              <input
                ref={fileInput}
                onChange={uploadFileImg}
                type='file' />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Nombre'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Correo'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder='Teléfono'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ width: '100%' }}
                variant='contained'
                onClick={() => onSubmit()}
              >EDITAR</Button>
              {error && <Typography variant='body1' style={{marginTop:14}} color="red">{error}</Typography>}
            </Grid>
          </Grid>
        </Card>
      </RootForm>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
        message={message}
      />
    </Root>
  )
}

export default EditClient