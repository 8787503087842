import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    background: linear-gradient(127deg, #FFA4B4 17.71%, #FFE1E6 39.58%, #FFA4B4 63.02%);
    height: 319px;
    margin-top: -127px;
`

export const RootAvatar = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 65px;
    flex-direction: column;
    align-items: center;
`
export const RootInfo = styled.div`
    display: flex;
    justify-content: space-around;
    padding-top: 25px;
    align-items: center;
`
export const avatarStyle = css`
    width: 85px !important;
    height: 85px !important;
`
export const ContainerAvatar = styled.div`
    padding:10px;
    border: 1px solid white;
    border-radius: 100px;
    position: relative;
`
