import React from 'react'
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import { Root, RootFloatButton } from './PreClient.style'
import { List } from './List/List'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';

const PreClient = () => {
    return (
        <Root>
            <MenuHeader
                goBack right={() => { }}
                title='Posibles clientes'
            />
            <FlotaingButton />
            <List />
        </Root>
    )
}


const FlotaingButton = () => {
    return (
        <Link to="/create_pre_client">
            <RootFloatButton>
                <AddIcon style={{ color: 'white' }} />
            </RootFloatButton>
        </Link>
    )
}

export default PreClient