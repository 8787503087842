import React, { useState } from 'react'
import { Root, RootBody, RootHeader, RootRight, RootTitle } from './CardExpand.style'
import { Typography } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CardExpand = ({ title, children }) => {
    const [active, setActive] = useState(false)
    return (
        <Root>
            <RootHeader>
                <RootTitle>
                    <Typography variant='body1'>{title}</Typography>
                </RootTitle>
                <RootRight onClick={() => setActive(!active)}>
                    {
                        active
                            ? <ExpandLessIcon />
                            : <ExpandMoreIcon />
                    }
                </RootRight>
            </RootHeader>
            {
                active
                && <RootBody>
                    {children}
                </RootBody>
            }

        </Root>
    )
}

export default CardExpand