import styled from "@emotion/styled";

export const Root = styled.div`
`
export const RootForm = styled.div`
    width: 93%;
    margin: auto;
`

export const DetailsRoot = styled.div`

`

export const DetailsItem = styled.div`
    margin-top: 15px;
`

export const RootTitle = styled.div`
   display: flex;
   justify-content: space-between;
`

export const RootButtons = styled.div`
   display: flex;
   justify-content: flex-end;
`

export const TimeContainer = styled.div`
    margin: auto;
    max-width: max-content;
    padding-bottom: 20px;
`

export const DetailsClient = styled.div`
    display: flex;
    align-items: center;
`

export const ListModalRoot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    > div {
        min-width: 90vw;
    }
`
