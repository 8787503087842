import styled from "@emotion/styled";

export const Root = styled.div`
    padding-bottom: 60px;
`

export const RootFloatButton = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 38px;
    background: #1A9EEF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 140px;
    right: 17px;
`