import React from 'react'
import { ContainerAvatar, Root, RootAvatar, avatarStyle } from './Header.style'
import { Avatar, Typography } from '@mui/material'
import { CircleLoading } from '@/pages/client_perfil/Header/Header'

const Header = ({
    data,
    load
}) => {
    return (
        <Root>
            <RootAvatar>
                <ContainerAvatar>
                    <Avatar className={avatarStyle} />
                    {
                        load && <CircleLoading />
                    }
                </ContainerAvatar>
                <Typography variant='h5' color="white" style={{marginTop:10}}>
                    {data?.title}</Typography>
                <Typography variant='subtitle1' color="white" style={{marginTop:10}}>
                    {data?.product.title}</Typography>
            </RootAvatar>
        </Root>
    )
}

export default Header