import { Avatar, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ListItemContainer, ListItemHead, ListItemRoot, Root, RootLoad } from './List.style'
import { Link } from 'react-router-dom'
import get, { ResponseData } from '@/api/preCustomer/get'

export const List = () => {
    const [load, setLoad] = useState(false)
    const [error, setError] = useState('')
    const [list, setList] = useState<ResponseData[]>([])

    const getCustomers = async () => {
        setLoad(true)
        const res = await get()
        setLoad(false)
        if (!res) {
            return console.log('Error al obtener clientes')
        }
        setList(res.data)
    }

    useEffect(() => {
        getCustomers()
    }, [])

    return (
        <Root>
            {
                load
                    ? (<RootLoad><CircularProgress /></RootLoad>)
                    : list.map(item => (
                        <Link to={`/pre_client_perfil?id=${item.id}`}>
                            <ListItem item={item} />
                        </Link>
                    ))
            }
        </Root>
    )
}

const ListItem = ({
    item
}) => {
    const [title, setTitle] = useState('')

    const handleObtainTitle = () => {
        if(item.name) {
            setTitle(item.name)
        } else if(item.phone) {
            setTitle(item.phone)
        } else if(item.instagram) {
            setTitle(item.instagram)
        } else if(item.email) {
            setTitle(item.email)
        } else if(item.reference) {
            setTitle(item.reference)
        } else if(item.comment) {
            setTitle(item.comment)
        } else {
            setTitle('-')
        }
    } 

    useEffect(() => {
        void handleObtainTitle()
    }, [item])

    return (<ListItemRoot>
        <ListItemHead>
            <div>
                <Avatar
                    src={item.img}
                    style={{ width: 40, height: 40 }} />
            </div>
            <ListItemContainer>
                <Typography variant='subtitle1'>{title}</Typography>
            </ListItemContainer>
        </ListItemHead>
    </ListItemRoot>
    )
}
