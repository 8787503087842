/* eslint-disable import/no-anonymous-default-export */
import errorLog from '@/utils/errorLog'
import Axios from 'axios'
import config from '@/config';

export default async (): Promise<Response | null> => {
    const path = '/pre-customer'
    const url = config.API_URL + path
    const headers = { ...config.APIHEADER }

    return Axios.get(url, { headers })
        .then(response => {
            return response.data
        })
        .catch(error => {
            errorLog({ path, url, req: null, error })
            return null
        })
}

interface Response {
    data: ResponseData[]
    error: {
        errorCode: number
        errorMessage: string
    }
}

export interface ResponseData {
    id: number
    name: string
    phone: string
    email: string
    instagram: string
    reference: string
    comment: string
}