import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import React, { useEffect, useState } from 'react'
import { Button, Card, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { Root, RootForm } from './EditPreClient.style';
import getOne from '@/api/preCustomer/getOne';
import { useSearchParams } from 'react-router-dom';
import update from '@/api/preCustomer/update';

const EditPreClient = () => {
  const [customer, setCustomer] = useState(null)
  const [error, setError] = useState<null | string>(null)
  const [load, setLoad] = useState(false)
  const [message, setMessage] = useState('')
  const [name, setName] = useState<null | string>(null)
  const [email, setEmail] = useState<null | string>(null)
  const [phone, setPhone] = useState<null | string>(null)
  const [instagram, setInstagram] = useState<null | string>(null)
  const [comment, setComment] = useState<null | string>(null)
  const [reference, setReference] = useState<null | string>(null)
  const [searchParams] = useSearchParams();

  const obtainClient = async () => {
    const id = searchParams.get('id')
    if (!id) {
      return console.log("Falta el id")
    }
    const res = await getOne(id)
    console.log("getOne", res)
    if (!res) {
      return alert('Error al obtener cliente')
    }
    setCustomer(res.data)
    setName(res.data.name)
    setEmail(res.data.email)
    setPhone(res.data.phone)
    setInstagram(res.data.instagram)
    setReference(res.data.reference)
    setComment(res.data.comment)
  }

  useEffect(() => {
    obtainClient()
  }, [])

  const editClient = async () => {
    const id = searchParams.get('id')
    setError('')
    let req: any = {
      id,
      name,
      phone,
      email,
      instagram,
      reference,
      comment
    }
    setLoad(true)
    const res = await update(req)
    setLoad(false)
    if (!res) {
      return setError('Error al actualizar posible cliente')
    }
    setMessage('¡Posible cliente editado con éxito!')
    await obtainClient()
  }

  const onSubmit = () => {
    setError('')
    editClient()
  }

  return (
    <Root>
      <MenuHeader
        goBack
        right={() => { }}
        title='Editar posible cliente'
      />
      <RootForm>
        <Card>
          <Grid style={{
            padding: 20
          }} container spacing={4} alignItems={'center'}>
            <Grid item xs={12} md={6}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Nombre'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder='Teléfono'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Correo'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                placeholder='Instagram'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                placeholder='Referencia'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder='Comentario'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ width: '100%' }}
                variant='contained'
                onClick={() => onSubmit()}
              >EDITAR</Button>
              {error && <Typography variant='body1' style={{marginTop:14}} color="red">{error}</Typography>}
            </Grid>
          </Grid>
        </Card>
      </RootForm>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
        message={message}
      />
    </Root>
  )
}

export default EditPreClient