import React, { useEffect, useState } from 'react'
import { Root, RootCard } from './PreClientPerfil.style'
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import CreateIcon from '@mui/icons-material/Create';
import Header from './Header/Header';
import { Link, useSearchParams } from 'react-router-dom';
import getOne from '@/api/preCustomer/getOne';
import Card from '@/components/Cards/Card/Card';
import { Button, Grid, Typography } from '@mui/material';
import getByCustomer from '@/api/preCustomerProduct/getByCustomer';

const PreClientPerfil = () => {
    const [load, setLoad] = useState(false)
    const [customer, setCustomer] = useState(null)
    const [products, setProducts] = useState(null)
    const [searchParams] = useSearchParams();

    const obtainAll = () => {
        obtainClient()
        obtainProducts()
    }
    useEffect(() => {
        obtainAll()
    }, [])

    const id = searchParams.get('id')
    const obtainClient = async () => {
        if (!id) {
            return console.log("Falta el id")
        }
        setLoad(true)
        const res = await getOne(id)
        console.log("getOne", res)
        setLoad(false)
        if (!res) {
            return alert('Error al obtener cliente')
        }
        setCustomer(res.data)
    }

    const obtainProducts = async () => {
        const res = await getByCustomer(id)
        if (!res) {
            return alert('Error al obtener productos')
        }
        setProducts(res.data)
    }

    return (
        <Root>
            <MenuHeader
                goBack
                right={
                    <Link to={`/edit_pre_client?id=${customer && customer.id}`}>
                        <CreateIcon style={{ color: 'white' }} />
                    </Link>
                }
                goBackColor={'white'}
            />
            <Header customer={customer} load={load} />
            <RootCard>
                <Card>
                    <Typography variant='subtitle1' style={{ marginBottom: 10 }}>
                        Instagram: {customer?.instagram}</Typography>
                    <Typography variant='subtitle1' style={{ marginBottom: 10 }}>
                        Comentario: {customer?.comment}</Typography>
                    <Typography variant='subtitle1'>
                        Referencia: {customer?.reference}</Typography>
                </Card>
            </RootCard>
            {products
                && products.map(item => (
                    <RootCard>
                        <Card>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography variant='subtitle1' style={{ marginBottom: 10 }}>
                                        {item?.product?.title}</Typography>
                                    <Typography variant='body1' style={{ marginBottom: 10 }}>
                                        {item?.description}</Typography>
                                </Grid>
                                <Grid item xs={2} container justifyContent={'flex-end'} alignItems={'center'}>
                                    <CreateIcon />
                                </Grid>
                            </Grid>
                        </Card>
                    </RootCard>
                ))}
            <div style={{ marginBottom: 40, textAlign: 'center' }}>
                <Typography>Agregar servicio</Typography>
            </div>
            <div style={{ width: '94%', margin: 'auto' }}>
                <Link to={`/create_client?preclientid=${id}`}>
                    <Button fullWidth variant='contained'>Convertir a cliente</Button>
                </Link>
            </div>
        </Root>
    )
}

export default PreClientPerfil