import React, { useEffect, useState } from 'react'
import { Root, RootCard, RootFloatButton } from './Header.style'
import { Grid, Typography } from '@mui/material'
import CardItem from '@/components/Cards/CardItem/CardItem'
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import get, { ResponseData } from '@/api/taskList/get';
const Header = () => {
    const [list, setList] = useState<ResponseData[]>([])

    const getTaskList = async () => {
        const res = await get()
        if (!res) {
            return console.log('Error al obtener listas')
        }
        setList(res.data)
    }

    useEffect(() => {
        void getTaskList()
    }, [])


    return (
        <Root>
            <FlotaingButton />
            <Grid container>
                <Grid xs={6}>
                    <RootCard>
                        <Typography variant='h6'>Hoy</Typography>
                    </RootCard>
                </Grid>
                <Grid xs={6}>
                    <RootCard>
                        <Typography variant='h6'>Programados</Typography>
                    </RootCard>
                </Grid>
                <Grid xs={6}>
                    <RootCard>
                        <Typography variant='h6'>Backlog</Typography>
                    </RootCard>
                </Grid>
                <Grid xs={6}>
                    <RootCard>
                        <Typography variant='h6'>Revisión</Typography>
                    </RootCard>
                </Grid>
                <Grid xs={12}>
                    <Typography variant='h6' style={{ paddingTop: 20, paddingBlock: 20 }}>Listas</Typography>
                </Grid>
                <Grid xs={12}>
                    {
                        list.map(item => (
                            <div  style={{ marginBottom: 13 }}>
                            <Link 
                                to={`/task_list?id=${item.id}&title=${item.title}`}>
                                <CardItem title={item.title} />
                            </Link>
                            </div>
                        ))
                    }
                </Grid>
            </Grid>
        </Root>

    )
}

const FlotaingButton = () => {
    return (
        <Link to="/create_task">
            <RootFloatButton>
                <AddIcon style={{ color: 'white' }} />
            </RootFloatButton>
        </Link>
    )
}

export default Header