import errorLog from '@/utils/errorLog'
import Axios from 'axios'
import config from '@/config';

// eslint-disable-next-line import/no-anonymous-default-export
export default async (id: any): Promise<Response | null> => {
    const path = `/company/customer/${id}`
    const url = config.API_URL + path
    const headers = { ...config.APIHEADER }

    return Axios.get(url, { headers })
        .then(response => {
            return response.data
        })
        .catch(error => {
            errorLog({ path, url, req: null, error })
            return null
        })
}

interface Response {
    data: ResponseData[]
    error: {
        errorCode: number
        errorMessage: string
    }
}

export interface ResponseData {
    id: number
    title: string
    img: string
}