import Axios from 'axios';
import config from '@/config';
import errorLog from '@/utils/errorLog';

export interface Request {
    name?: string;
    phone?: string;
    instagram?: string;
    email?: string;
    reference?: string;
    comment?: string;
    products?: RequestProducts[]
}

interface RequestProducts {
    product: number;
    description: string;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default async (req: Request): Promise<Response | null> => {
    const path = '/pre-customer'
    const url = config.API_URL + path
    const body = req
    const headers = { ...config.APIHEADER }
    return Axios.post(url, body, { headers })
        .then(response => {
            return response.data
        })
        .catch(error => {
            errorLog({ path, url, req: null, error })
            return null
        })
}

interface Response {
    data: any
    error: {
        errorCode: number
        errorMessage: string
    }
}
