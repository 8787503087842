import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import React, { useEffect, useState } from 'react'
import { Button, Divider, Grid, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { Root, RootForm } from './CreatePreClient.style';
import create from '@/api/preCustomer/create';
import Card from '@/components/Cards/Card/Card';
import get from '@/api/product/get';

const CreatePreClient = () => {
    const [error, setError] = useState(null)
    const [load, setLoad] = useState(false)
    const [message, setMessage] = useState('')
    const [productList, setProductList] = useState(null)

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [instagram, setInstagram] = useState(null)
    const [reference, setReference] = useState(null)
    const [comment, setComment] = useState(null)
    const [products, setProducts] = useState(null)

    const empityState = () => {
        setName('')
        setEmail('')
        setPhone('')
        setInstagram('')
        setReference('')
        setComment('')
        setProducts([{
            product: null,
            description: ''
        }])
    }

    const onSubmit = async () => {
        setError('')
        if (!(name || phone || email || instagram || reference || comment)) {
            return setError('Debes ingresar al menos un dato')
        }
        if(products.find(item => !item.product)) {
            return setError('Te falta seleccionar el tipo de servicio')
        }
        let req: any = {
            name,
            phone,
            instagram,
            email,
            reference,
            comment,
            products,
        }
        setLoad(true)
        const res = await create(req)
        console.log("create",res)
        setLoad(false)
        if (!res) {
            return setError('Error al crear cliente')
        }
        empityState()
        setMessage('¡Posible cliente creado con éxito!')
    }

    const handleGetProducts = async () => {
        const res = await get()
        console.log("handleGetProducts", res)
        if (!res) {
            return console.log('Error al obtener productos')
        }
        setProductList(res.data)

        if (!products) {
            setProducts([{
                product: null,
                description: ''
            }])
        }
    }

    useEffect(() => {
        void handleGetProducts()
    }, [])

    const handleAddProductInput = () => {
        const state = Object.assign([], products)
        state.push({
            product: null,
            description: ''
        })
        setProducts(state)
    }
    const handleRemoveProductInput = () => {
        const state = Object.assign([], products)
        state.pop()
        setProducts(state)
    }
    const onChangeDescription = (key, value) => {
        const state = Object.assign([], products)
        state[key].description = value
        setProducts(state)
    }
    const onChangeProductId = (key, value) => {
        const state = Object.assign([], products)
        state[key].product = value
        setProducts(state)
    }

    return (
        <Root>
            <MenuHeader
                goBack
                right={() => { }}
                title='Agregar posible cliente'
            />
            <RootForm>
                <Card>
                    <Grid style={{ padding: 20 }} container spacing={2} alignItems={'center'}>
                        <Grid item xs={12}>
                            {
                                products
                                && products.map((itemProduct, key) => (
                                    <Card>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    label="Servicio"
                                                    select
                                                    variant='outlined'
                                                    fullWidth
                                                    value={itemProduct.id}
                                                    onChange={(e) => onChangeProductId(key, e.target.value)}
                                                >
                                                    {
                                                        productList && productList.map(item => (
                                                            <MenuItem value={item.id}>{item.title}</MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    onChange={(e) => onChangeDescription(key, e.target.value)}
                                                    placeholder='Descripción servicio'
                                                    fullWidth
                                                    value={itemProduct.description}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                ))
                            }
                            {products && products.length > 1
                                && <Button onClick={handleRemoveProductInput} variant='contained' color="secondary">Borrar último</Button>}
                            <Button onClick={handleAddProductInput} variant='contained'>Agregar servicio</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{ marginBottom: 30, marginTop: 20 }}></Divider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                onChange={(e) => setName(e.target.value)}
                                placeholder='Nombre cliente'
                                fullWidth
                                value={name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder='Teléfono'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={instagram}
                                onChange={(e) => setInstagram(e.target.value)}
                                placeholder='@ Instagram'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Correo'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={reference}
                                onChange={(e) => setReference(e.target.value)}
                                placeholder='Referencia'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder='Comentario'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{ width: '100%' }}
                                variant='contained'
                                onClick={() => onSubmit()}
                            >Crear posible cliente</Button>
                            {error && <Typography variant='body1' color="red" style={{ marginTop: 20 }}>{error}</Typography>}
                        </Grid>
                    </Grid>
                </Card>
            </RootForm>
            <Snackbar
                open={Boolean(message)}
                autoHideDuration={6000}
                onClose={() => setMessage('')}
                message={message}
            />
        </Root>
    )
}

export default CreatePreClient