import styled from "@emotion/styled";

export const Root = styled.div`
    padding-top: 60px;
    padding-bottom: 28px;
    display: flex;
    justify-content: space-between;
    width: 93%;
    margin: auto;
    position: relative;

`

export const LogoImage = styled.img`
    width: 30px;
    height: 30px;
`

export const RootTitle = styled.div`
position: absolute;
top: 69px;
left: 50%;
transform: translateX(-50%);
`