
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import { Card, Checkbox, Typography } from '@mui/material';
import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { ListItemRoot, RootList } from './TaskList.style';

const TaskList = () => {
    const [searchParams] = useSearchParams();

    return (
        <div>
            <MenuHeader
                goBack
                goBackTo={'/task'}
                right={() => { }}
                title={searchParams.get('title')}
            />
            <RootList>
                <ListItem />
            </RootList>
        </div>
    )
}

const ListItem = () => {
    return (
        <ListItemRoot>
            <div>
                <Checkbox />
            </div>
            <div style={{paddingLeft: 10, flex:1, paddingRight:20}}>
                <Typography variant='body1'  style={{paddingBottom:8}}><b>Esta es una tarea con un texto muy largo</b></Typography>
                <Typography variant='caption'>12/05/2023 11:00</Typography>
            </div>
            <div>
                <Typography variant='caption'>MT</Typography>
                <Typography  variant='caption'>Eliza Cueto</Typography>
            </div>
        </ListItemRoot>
    )
}

export default TaskList