import React from 'react'
import { ContainerAvatar, Root, RootAvatar, RootInfo, avatarStyle } from './Header.style'
import { Avatar, Typography } from '@mui/material'
import { CircleLoading } from '@/pages/client_perfil/Header/Header'

const Header = ({
    company,
    load
}) => {
    return (
        <Root>
            <RootAvatar>
                <ContainerAvatar>
                    <Avatar src={company?.img} className={avatarStyle} />
                    {
                        load && <CircleLoading />
                    }
                </ContainerAvatar>
                <Typography variant='h5' color="white" style={{marginTop:10}}>
                    {company?.title}</Typography>
            </RootAvatar>
            <RootInfo>
                <Typography color="white">{company?.email}</Typography>
                <Typography color="white">{company?.phone}</Typography>
            </RootInfo>
        </Root>
    )
}

export default Header