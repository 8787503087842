import { Avatar, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ListItemContainer, ListItemHead, ListItemRoot, RootLoad } from './List.style'
import get, { ResponseData } from '@/api/customer/get'
import getStatus from '@/api/productStatus/get'
import getSubStatus from '@/api/productSubStatus/get'
import { Link } from 'react-router-dom'
import { Root } from '../Client style'
import historyCompany from '@/api/companyProduct/historyCompany'
import NoteStatus from '@/components/StatusProduct/NoteStatus/NoteStatus'
import getOne from '@/api/companyProduct/getOne'
import CardStatus from '@/components/StatusProduct/CardStatus/CardStatus'

export const List = () => {
    const [load, setLoad] = useState(false)
    const [error, setError] = useState('')
    const [list, setList] = useState<ResponseData[]>([])
    const [listStatus, setListStatus] = useState([])
    const [listSubStatus, setListSubStatus] = useState([])

    const getCustomers = async () => {
        setLoad(true)
        const res = await get()
        setLoad(false)
        if (!res) {
            return console.log('Error al obtener clientes')
        }
        setList(res.data)
    }

    const obtainStatus = async () => {
        const res = await getStatus()
        if (!res) {
            return console.log('Error al obtener estados')
        }
        setListStatus(res.data)
    }
    const obtainSubStatus = async () => {
        const res = await getSubStatus()
        if (!res) {
            return console.log('Error al obtener estados')
        }
        setListSubStatus(res.data)
    }

    useEffect(() => {
        getCustomers()
        obtainStatus()
        obtainSubStatus()
    }, [])


    return (
        <Root>
            {
                load
                    ? (<RootLoad><CircularProgress /></RootLoad>)
                    : list.map(item => (
                        <Link to={`/client_perfil?id=${item.id}`}>
                            <ListItem item={item} />
                        </Link>
                    ))
            }
        </Root>
    )
}

const ListItem = ({
    item
}) => {
    const [historyLast, setHistoryLast] = useState(null)
    const [companyProduct, setCompanyProduct] = useState(null)

    const obtainLastHistory = async () => {
        const res = await historyCompany(item.company[0].id)
        if (!res) {
            return console.log("Error al obtener history")
        }
        setHistoryLast(res.data)
    }
    useEffect(() => {
        if (item) {
            if (item.company.length > 0) {
                void obtainLastHistory()
            }
        }
    }, [item])

    const obtainProduct = async () => {
        const res = await getOne(historyLast.productId)
        if (!res) {
            return alert('Error al obtener producto')
        }
        setCompanyProduct(res.data)
    }

    useEffect(() => {
        if (historyLast) {
            void obtainProduct()
        }
    }, [historyLast])

    return (<ListItemRoot>
        <ListItemHead>
            <div>
                <Avatar
                    src={item.img}
                    style={{ width: 70, height: 70 }} />
            </div>
            <ListItemContainer>
                <Typography variant='h6'>{item.name}</Typography>
                {
                    historyLast
                    && historyLast.note &&
                    <NoteStatus note={historyLast.note} date={historyLast.date} vertical />
                }
            </ListItemContainer>
        </ListItemHead>
        {companyProduct?.productStatus && <CardStatus
            status={companyProduct.productStatus}
            subStatus={companyProduct.productSubStatus}
            companyProductId={companyProduct.id}
            onChange={() => obtainLastHistory()}
        />}
    </ListItemRoot>
    )
}
