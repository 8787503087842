import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import React, { useRef, useState } from 'react'
import { Avatar, Button, Card, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { Root, RootForm } from './CreateUser.style';
import uploadImg from '@/api/users/uploadImg';
import create, { Request } from '@/api/users/create';

const CreateUser = () => {
  const [error, setError] = useState('')
  const [lookError, setLookError] = useState(false)
  const [load, setLoad] = useState(false)
  const [message, setMessage] = useState('')
  const [img, setImg] = useState<any>(null)
  const [first_name, setFirst_name] = useState(null)
  const [last_name, setLast_name] = useState(null)
  const [phone, setPhone] = useState(null)

  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [userType, setUserType] = useState(1)

  const uploadImageUrl = async (objectImage) => {
    setLoad(true)
    const res = await uploadImg(objectImage)
    setLoad(false)
    if (!res) {
      setError('Error al cargar imagen Usuario')
      return null
    }
    if (!res.data) {
      if (res.error) {
        setError(res.error.errorMessage)
        return null
      } else {
        setError('Error al cargar imagen Usuario')
        return null
      }
    }
    return res.data.url
  }

  const createUser = async () => {
    setError('')
    let req: Request = {
      first_name,
      last_name,
      phone,
      email,
      password,
      img,
      userType
    }
    if (img) {
      const res = await uploadImageUrl(img)
      if (!res) { return null }
      req.img = res
    }
    setLoad(true)
    const res = await create(req)
    setLoad(false)
    if (!res) {
      return setError('Error al crear usuario')
    }
    setMessage('Usuario creado con éxito!')
    empityState()
  }

  const fileInput = useRef(null)
  const empityState = () => {
    fileInput.current.value = ''
    setImg(null)
    setFirst_name(null)
    setLast_name(null)
    setPhone(null)
    setEmail(null)
    setPassword(null)
    setUserType(null)
  }

  const onSubmit = () => {
    setLookError(false)
    setError('')
    if (!password && !email) {
      setLookError(true)
      return setError('Debes ingresar al menos un correo y contraseña sobre el usuario')
    }
    createUser()
  }


  const uploadFileImg = event => {
    if (event.target.files && event.target.files[0]) {
      const i = event.target.files[0]
      setImg(i)
    }
  }

  return (
    <Root>
      <MenuHeader
        goBack
        right={() => { }}
        title='Crear usuario'
      />
      <RootForm>
        <Card>
          <Grid style={{
            padding: 20
          }} container spacing={4} alignItems={'center'}>
            <Grid item xs={12}>
              <Typography>Foto Usuario</Typography>
            </Grid>
            <Grid item xs={2}>
              <Avatar src={img && URL.createObjectURL(img) ? URL.createObjectURL(img) : null} />
            </Grid>
            <Grid item xs={10}>
              <input
                ref={fileInput}
                type='file'
                onChange={uploadFileImg}
                disabled={load}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder='Nombre'
                fullWidth
                value={first_name}
                onChange={(e) => setFirst_name(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder='Apellido'
                fullWidth
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder='Correo'
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder='Teléfono'
                fullWidth
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder='Contraseña'
                type='password'
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={onSubmit}
                style={{ width: '100%' }}
                variant='contained'>Crear Cliente</Button>
            </Grid>
          </Grid>
        </Card>
      </RootForm>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
        message={message}
      />
    </Root>
  )
}

export default CreateUser