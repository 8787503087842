import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    position: fixed;
    bottom: 20px;
    background-color: white;
    border-radius: 31px;
    width: 93%;
    height: 96px;
    left: 50%;
    transform: translateX(-50%);
    transition: height 0.4s ease-in-out;
    z-index: 10;
    overflow: hidden;
    box-shadow: 0px 8px 14px 0px rgba(174, 192, 203, 0.42);
`
export const rootActive = css`
    height: 84% !important;
`

export const RootCard = styled.div`
    background-color: white;
    border-radius: 31px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
`

export const RootContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 96px;
    background-color: white;
    z-index: 2;
`

export const RootIcon = styled.div`

`

export const MenuListRoot = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    padding-left: 12px;
    padding-top: 50px;
    position: absolute;
    transition: opacity 0.2s;
    opacity: 0;
`
export const menuListActive = css`
    opacity: 1 !important;
`


export const MenuItemRoot = styled.div`
   margin-bottom: 20px;
`