import React, { useEffect, useState } from 'react'
import { Root, RootListCompany } from './ClientPerfil.style'
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import CreateIcon from '@mui/icons-material/Create';
import Header from './Header/Header';
import ListCompany from './ListCompany/ListCompany';
import { Link, useSearchParams } from 'react-router-dom';
import getOne from '@/api/customer/getOne';
const ClientPerfil = () => {
    const [load, setLoad] = useState(false)
    const [customer, setCustomer] = useState(null)
    const [searchParams] = useSearchParams();

    const obtainClient = async () => {
        const id = searchParams.get('id')
        if(!id) {
            return console.log("Falta el id")
        }
        setLoad(true)
        const res = await getOne(id)
        setLoad(false)
        if(!res) {
            return alert('Error al obtener cliente')
        }
        setCustomer(res.data)
    } 
    useEffect(() => {
        obtainClient()
    },[])

    return (
        <Root>
            <MenuHeader
                goBack
                goBackTo="/client"
                right={
                    <Link to={`/edit_client?id=${customer && customer.id}`}>
                    <CreateIcon style={{ color: 'white' }} />
                    </Link>
                }
                goBackColor={'white'}
            />
            <Header customer={customer} load={load} />
            <RootListCompany>
                <ListCompany />
            </RootListCompany>
        </Root>
    )
}

export default ClientPerfil