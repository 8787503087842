import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Root, RootForm } from './EditProduct.style';
import uploadImg from '@/api/customer/uploadImg';
import getStatus from '@/api/productStatus/get'
import getSubStatus from '@/api/productSubStatus/get'
import update from '@/api/companyProduct/update'
import { useSearchParams } from 'react-router-dom';
import getOne from '@/api/companyProduct/getOne';
import getProduct from '@/api/product/get';

const EditProduct = () => {
  const [listProduct, setListProduct] = useState([])
  const [listStatus, setListStatus] = useState([])
  const [listSubStatus, setListSubStatus] = useState([])
  const [error, setError] = useState<null | string>(null)
  const [load, setLoad] = useState(false)
  const [title, setTitle] = useState<null | string>(null)
  const [product, setProduct] = useState<null | any>(null)
  const [searchParams] = useSearchParams();

  const obtainClient = async () => {
    const id = searchParams.get('id')
    if (!id) {
      return console.log("Falta el id")
    }
    const res = await getOne(id)
    console.log("getOne", res)
    if (!res) {
      return alert('Error al obtener cliente')
    }
    setTitle(res.data.title)
    setProduct(res.data.product.id)
  }

  useEffect(() => {
    obtainClient()
  }, [])

  const obtainProducts = async () => {
    const res = await getProduct()
    console.log("obtainProducts", res)
    if (!res) {
      return setError('Error al obtener productos')
    }
    setListProduct(res.data)
  }

  const obtainStatus = async () => {
    const res = await getStatus()
    if (!res) {
      return console.log('Error al obtener estados')
    }
    setListStatus(res.data)
  }
  const obtainSubStatus = async () => {
    const res = await getSubStatus()
    if (!res) {
      return console.log('Error al obtener estados')
    }
    setListSubStatus(res.data)
  }

  useEffect(() => {
    obtainStatus()
    obtainSubStatus()
    obtainProducts()
  }, [])

  const editProduct = async () => {
    const id = searchParams.get('id')
    setError('')
    let req: any = {
      id,
      title,
      product
    }
    setLoad(true)
    const res = await update(req)
    console.log("update",res)
    setLoad(false)
    if (!res) {
      return setError('Error al editar')
    }
    alert('Producto Editado!')
  }

  const onSubmit = () => {
    setError('')
    editProduct()
  }

  return (
    <Root>
      <MenuHeader
        goBack
        right={() => { }}
        title='Editar Producto'
      />
      <RootForm>
        <Card>
          <Grid style={{
            padding: 20
          }} container spacing={4} alignItems={'center'}>
            <Grid item xs={12}>
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder='Título'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel shrink id="product-select">Selecciona un producto</InputLabel>
                <Select
                  labelId="product-select"
                  id="product-select"
                  value={product}
                  onChange={e => setProduct(e.target.value)}
                >
                  {listProduct.map(item => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ width: '100%' }}
                variant='contained'
                onClick={() => onSubmit()}
              >EDITAR</Button>
            </Grid>
          </Grid>
        </Card>
      </RootForm>
    </Root>
  )
}

export default EditProduct