import styled from "@emotion/styled";

export const Root = styled.div`
    padding-left: 13px;
    padding-right: 13px;
`

export const RootCard = styled.div`
    width: 90%;
    margin: auto;
    height: 85px;
    border-radius: 11px;
    background: #FFF;
    box-shadow: 0px 8px 14px 0px rgba(174, 192, 203, 0.42);
    margin-bottom: 20px;
    > h6 {
        padding-top: 14px;
        padding-left: 20px;
    }
`

export const RootFloatButton = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 38px;
    background: #1A9EEF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 140px;
    right: 17px;
`
