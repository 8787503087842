import styled from "@emotion/styled";

export const RootList = styled.div`
    width: 93%;
    margin: auto;
`

export const ListItemRoot = styled.div`
    margin-bottom: 10px;
    border-radius: 11px;
    background: #FFF;
    box-shadow: 0px 8px 14px 0px rgba(174, 192, 203, 0.42);
    padding: 20px;
    display: flex;
    align-items: center;
    > div:last-child {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;
    }
`
