import React from 'react'
import { ContainerAvatar, Root, RootAvatar, RootCircleLoading, RootInfo, avatarStyle } from './Header.style'
import { Avatar, CircularProgress, Typography } from '@mui/material'

const Header = ({
    customer,
    load
}) => {
    return (
        <Root>
            <RootAvatar>
                <ContainerAvatar>
                    <Avatar src={null} className={avatarStyle} />
                    {
                        load && <CircleLoading />
                    }
                </ContainerAvatar>
                <Typography variant='h5' color="white" style={{ marginTop: 10 }}>
                    {customer?.name}
                </Typography>
            </RootAvatar>
            <RootInfo>
                <Typography color="white">{customer?.email}</Typography>
                <Typography color="white">{customer?.phone}</Typography>
            </RootInfo>
        </Root>
    )
}

export const CircleLoading = () => {
    return (
        <RootCircleLoading>
            <CircularProgress />
        </RootCircleLoading>
    )
}

export default Header