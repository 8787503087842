import Axios from 'axios';
import config from '@/config';
import errorLog from '@/utils/errorLog';
import { ResponseData } from './get';

export interface Request {
  name?: string;
  phone?: string;
  email?: string;
  customerType?: number;
  customerStatus?: number;
  customerSubStatus?: number;
  companyTitle?: string;
  companyImg?: string;
  companyInstagram?: string;
  img?: string;
  preClientId?: number;
  products?: Products[];
}
interface Products {
  product: number;
  description: string;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default async (req: Request): Promise<Response | null> => {
  const path = '/customer'
  const url = config.API_URL + path
  const body = req
  const headers = { ...config.APIHEADER }
  return Axios.post(url, body, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      errorLog({ path, url, req: null, error })
      return null
    })
}

interface Response {
  data: ResponseData[]
  error: {
    errorCode: number
    errorMessage: string
  }
}
