import React from 'react'
import { LogoImage, Root, RootTitle } from './MenuHeader.style'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link, useNavigate } from 'react-router-dom';
const logo = require('@/assets/img/logo/isologo-teknomaster-50w.png')

interface Props {
  activeMenu?: any
  setActiveMenu?: any
  goBack?: boolean
  right?: any
  title?: string
  goBackColor?: any
  goBackTo?: any
}

const MenuHeader:React.FC<Props> = ({
  activeMenu,
  setActiveMenu,
  goBack,
  right,
  title,
  goBackColor,
  goBackTo
}) => {
  const navigate = useNavigate();
  return (
    <Root>
      {
        goBack 
        ? <div onClick={() => navigate(goBackTo ? goBackTo : -1)}>
            <ChevronLeftIcon 
              style={{color: goBackColor ? goBackColor : ''}} 
              fontSize='large' /></div>
        : <LogoImage src={logo} />
      }
      {
        title ? <RootTitle><Typography variant='subtitle1'><b>{title}</b></Typography></RootTitle> : null
      }
      {right ? right : 
      <div onClick={() => setActiveMenu(!activeMenu)}>
        {
          activeMenu
            ? <CloseIcon style={{ fontSize: 40 }} />
            : <MenuIcon style={{ fontSize: 40 }} />
        }
      </div>}
    </Root>
  )
}

export default MenuHeader