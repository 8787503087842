import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import {
  Outlet,
} from "react-router-dom";
import { theme } from '../theme';
import Home from '@/pages/home';
import Client from '@/pages/client';
import { Root } from './Layout.style';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Login from '@/pages/login/Login';
import token from '@/api/users/token';
import { AuthProvider } from '@/provider/AuthProvider';

const Layout = () => {
  const [isAuth, setIsAuth] = useState(null)

  useEffect(() => {
    void verifyAuth()
  }, [])

  const verifyAuth = async () => {
    const localToken = localStorage.getItem('token')
    if (localToken) {
      const res = await token({ token: localToken })
      if (!res) {
        return console.log('Error al verificar token')
      }
      if (!res.data) {
        setIsAuth(false)
        console.log('Token Incorrecto')
      } else {
        setIsAuth(true)
      }
    } else {
      setIsAuth(false)
    }
  }

  return (
    <>
      <AuthProvider>
        <Root>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
              <Outlet />
            </ThemeProvider>
          </LocalizationProvider>
        </Root>
      </AuthProvider>
    </>
  )
}

export default Layout