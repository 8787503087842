import { Typography } from '@mui/material'
import React from 'react'
import { Root } from './WelcomeCard.style'

const WelcomeCard = () => {
  return (
    <Root>
      <div>
        <Typography variant='h4' color='white'><b>Bienvenid@</b></Typography>
        <Typography variant='h4' color='white'>Backoffice Official</Typography>
        <div style={{ marginTop: 60, textAlign:'right' }}>
          <Typography variant='h6' color='white'>Beta Versión 0.1.3</Typography>
        </div>
      </div>
    </Root>
  )
}

export default WelcomeCard