import { Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { LogoHeader, Root, RootCard, RootForm, RootHeader } from './Login.style'
import login from '@/api/users/login'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/provider/AuthProvider'

const logo = require('@/assets/img/logo/imagotipo-teknomaster-colorlight.png')

const Login = () => {
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [error, setError] = useState('')
    const [load, setLoad] = useState(false)

    const auth = useAuth()

    const navigate = useNavigate()

    const onSubmit = async () => {
        setError('')
        setLoad(true)
        const res = await login({ email, password })
        console.log("res", res)
        setLoad(false)
        if (!res) {
            return setError('Error en el sistema')
        }
        if (!res.data) {
            if(res?.error) {
                return setError(res.error.errorMessage)
            }
            return setError('Error')
        }

        auth.signin(res.data, () => {
            navigate('home', { replace: true })
        })
        localStorage.setItem('token', res.token)
    }

    return (
        <Root>
            <RootHeader>
                <LogoHeader
                    src={logo}
                />
                <div style={{ marginTop: -30, textAlign: 'center' }}>
                    <Typography variant='body1' color='white'><b>BACKOFFICE</b></Typography>
                    <Typography variant='body1' color='white'>Beta v0.1.3</Typography>
                </div>
            </RootHeader>
            <RootCard>
                <Typography color="#343540" variant='h4'><b>Iniciar Sesión</b></Typography>
                <RootForm>
                    <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Correo'
                        style={{ marginTop: 20 }}
                        fullWidth
                    />
                    <TextField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Constraseña'
                        type="password"
                        style={{ marginTop: 20 }}
                        fullWidth
                    />
                </RootForm>
                <div style={{ height: 130, display: 'flex', flexDirection: 'column' }}>
                    <Button
                        onClick={onSubmit} variant='contained'>Entrar</Button>
                    {error && <Typography variant='body1' color="red">{error}</Typography>}
                </div>
            </RootCard>
        </Root>
    )
}

export default Login