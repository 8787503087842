import React, { useEffect, useState } from 'react'
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import CreateIcon from '@mui/icons-material/Create';
import { Root, RootListCompany } from './CompanyPerfil.style';
import Header from './Header/Header';
import ListCompany from './ListCompany/ListCompany';
import { Link, useSearchParams } from "react-router-dom";
import getOne from '@/api/company/getOne';
import getByCompany from '@/api/companyProduct/getByCompany';

const CompanyPerfil = () => {
    const [load, setLoad] = useState(false)
    const [company, setCompany] = useState(null)
    const [listCompanyProduct, setListCompanyProduct] = useState(null)
    const [searchParams] = useSearchParams();

    const obtainAll = async () => {
        await obtainCompany()
        await obtainCompanyProducts()
    }
    useEffect(() => {
        obtainAll()
    }, [])

    const obtainCompany = async () => {
        const id = searchParams.get('id')
        if (!id) {
            return console.log("Falta el id")
        }
        setLoad(true)
        const res = await getOne(id)
        setLoad(false)
        console.log("getOne", res)
        if (!res) {
            return alert('Error al obtener empresa')
        }
        setCompany(res.data)
    }

    const obtainCompanyProducts = async () => {
        const id = searchParams.get('id')
        const res = await getByCompany(id)
        if (!res) {
            return alert('Error al obtener productos')
        }
        setListCompanyProduct(res.data)
        console.log("getByCompany", res)

    }

    return (
        <Root>
            <MenuHeader
                goBack
                right={
                    <Link to={`/edit_company?id=${company && company.id}`}>
                        <CreateIcon style={{ color: 'white' }} />
                    </Link>
                }
                goBackColor={'white'}
            />
            <Header company={company} load={load} />
            <RootListCompany>
                <ListCompany load={load} obtainAll={obtainAll} list={listCompanyProduct} />
            </RootListCompany>
        </Root>
    )
}

export default CompanyPerfil