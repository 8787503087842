import styled from "@emotion/styled";

export const Root = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    > div {
        min-width: 90vw;
    }
`
export const RootTitle = styled.div`
   display: flex;
   justify-content: space-between;
`

export const RootButtons = styled.div`
   display: flex;
   justify-content: flex-end;
   margin-top: 30px;
`

export const RootBody = styled.div`
    max-height: 50vh;
    overflow-y: scroll;
    padding-bottom:30px;
`
