import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    background: linear-gradient(127deg, #42C2F5 8.33%, #C1BFFF 42.71%, #42C2F5 70.31%, #42C2F5 94.79%);
    height: 319px;
    margin-top: -127px;
`

export const RootAvatar = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 65px;
    flex-direction: column;
    align-items: center;
`
export const RootInfo = styled.div`
    display: flex;
    justify-content: space-around;
    padding-top: 25px;
    align-items: center;
`
export const avatarStyle = css`
    width: 85px !important;
    height: 85px !important;
`
export const ContainerAvatar = styled.div`
    padding:10px;
    border: 1px solid white;
    border-radius: 100px;
    position: relative;
`

export const RootCircleLoading = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    > span {
        width: 118px !important;
        height: 118px !important;
        color: white !important;
    }
    > span > svg > circle {
        stroke-width: 2;
    }
`
