import CircleOrange from '@/components/ui/CircleOrange'
import { Typography } from '@mui/material'
import React from 'react'
import { Root, verticalStyle } from './NoteStatus.style'
import moment from 'moment'

interface Props {
    note: string
    date: string
    vertical?: boolean
}

const NoteStatus = ({
    note,
    date,
    vertical
}: Props) => {
    return (
        <Root>
            <div>
                <CircleOrange />
            </div>
            <div className={vertical && verticalStyle} >
                <Typography variant='body1'><b>{note}</b></Typography>
                <Typography variant='caption'>{date ? moment(date).format('DD/MM/YYYY') : null}</Typography>
            </div>
        </Root>
    )
}

export default NoteStatus