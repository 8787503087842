import React, { useEffect, useState } from 'react'
import { ListItemContainerState, ListItemHead, ListItemRoot, ListItemStates, ListItemTitle, ModalContainer, Root } from './CardStatus.style'
import getStatus from '@/api/productStatus/get'
import getSubStatus from '@/api/productSubStatus/get'
import { Box, Button, Card, Grid, Menu, MenuItem, Modal, TextField, Typography } from '@mui/material'
import { ResponseDataProductStatus, ResponseDataProductSubStatus } from '@/api/companyProduct/getOne'
import putStatus from '@/api/companyProduct/putStatus'
import putSubStatus from '@/api/companyProduct/putSubStatus'
import { DatePicker } from '@mui/x-date-pickers'

interface Props {
    status: ResponseDataProductStatus
    subStatus: ResponseDataProductSubStatus
    companyProductId: string | number
    onChange: any
}

const CardStatus: React.FC<Props> = ({
    status,
    subStatus,
    companyProductId,
    onChange
}) => {
    const [listStatus, setListStatus] = useState([])
    const [listSubStatus, setListSubStatus] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElSub, setAnchorElSub] = useState(null)

    const [statusValue, setStatusValue] = useState(null)
    const [subStatusValue, setSubStatusValue] = useState(null)
    const [statusTitle, setStatusTitle] = useState(null)
    const [subStatusTitle, setSubStatusTitle] = useState(null)

    const [note, setNote] = useState('')
    const [date, setDate] = useState(null)
    const [modalNote, setModalNote] = useState(false)
    const [modalIdStatus, setModalIdStatus] = useState(null)
    const [modalSubNote, setModalSubNote] = useState(false)
    const [modalIdSubStatus, setModalIdSubStatus] = useState(null)

    const obtainStatusValues = () => {
        if (!status || !subStatus) {
            return console.log('Faltan los estados')
        }
        setStatusValue(status.id)
        setSubStatusValue(subStatus.id)
        setStatusTitle(status.title)
        setSubStatusTitle(subStatus.title)
    }

    useEffect(() => {
        void obtainStatusValues()
    }, [status, subStatus])

    const obtainStatus = async () => {
        const res = await getStatus()
        if (!res) {
            return console.log('Error al obtener estados')
        }
        setListStatus(res.data)
    }
    const obtainSubStatus = async () => {
        const res = await getSubStatus()
        if (!res) {
            return console.log('Error al obtener sub estados')
        }
        setListSubStatus(res.data)
    }

    useEffect(() => {
        void obtainStatus()
        void obtainSubStatus()
    }, [status, subStatus])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (idStatus?: any) => {
        setAnchorEl(null);
        if (idStatus) {
            setModalIdStatus(idStatus)
            setModalNote(true)
        }
    };
    const handleClickSub = (event) => {
        setAnchorElSub(event.currentTarget);
    };
    const handleCloseSub = (idSubStatus?: any) => {
        setAnchorElSub(null);
        if (idSubStatus) {
            setModalIdSubStatus(idSubStatus)
            setModalSubNote(true)
        }
    };

    const onSubmitStatus = () => {
        if (modalIdStatus) {
            changeStatus(modalIdStatus)
            setModalNote(false)
        }
    }
    const onSubmitSubStatus = () => {
        if (modalIdSubStatus) {
            changeSubStatus(modalIdSubStatus)
            setModalSubNote(false)
        }
    }

    const changeStatus = async (idStatus) => {
        const req = {
            id: companyProductId,
            productStatus: idStatus,
            note,
            date: date.format()
        }
        const res = await putStatus(req)
        if (!res) {
            return alert('Error al cambiar estado')
        }
        onChange()
    }
    const changeSubStatus = async (idSubStatus) => {
        const req = {
            id: companyProductId,
            productSubStatus: idSubStatus,
            note,
            date
        }
        const res = await putSubStatus(req)
        console.log("putStatus", res)
        if (!res) {
            return alert('Error al cambiar sub estado')
        }
        onChange()
    }
    return (
        <Root>
            <ListItemRoot>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => handleClose()}
                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                    {
                        listStatus.map(item => (
                            <MenuItem selected={status.id === item.id} onClick={() => handleClose(item.id)}>{item.title}</MenuItem>
                        ))
                    }
                </Menu>
                <Menu
                    id="basic-sub-menu"
                    anchorEl={anchorElSub}
                    open={Boolean(anchorElSub)}
                    onClose={() => handleCloseSub()}
                    MenuListProps={{ 'aria-labelledby': 'basic-sub-button' }}>
                    {
                        listSubStatus
                            .filter(item => item.productStatus.id === status.id)
                            .map(item => (
                                <MenuItem onClick={() => handleCloseSub(item.id)}>{item.title}</MenuItem>
                            ))
                    }
                </Menu>
                <ListItemHead>
                    <ListItemTitle>
                        <ListItemStates>
                            <div onClick={e => handleClick(e)}>
                                <Typography variant='body2' color="white">{statusTitle}</Typography>
                            </div>
                            <div onClick={e => handleClickSub(e)}>
                                <Typography variant='body2' color="white">{subStatusTitle}</Typography>
                            </div>
                        </ListItemStates>
                    </ListItemTitle>
                </ListItemHead>
                {/* <ListItemContainerState>
                    <div>
                        <CircleOrange />
                    </div>
                    <div>
                        <Typography variant='body1'><b>Volver a hablar en enero</b></Typography>
                        <Typography variant='caption'>03/03/23</Typography>
                    </div>
                </ListItemContainerState> */}
                {/* <ListItemCredentials>
                <Credentials />
            </ListItemCredentials> */}
                <Modal open={modalNote}>
                    <ModalContainer>
                        <Card>
                            <Box padding={3}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Crea una nota</Typography>
                                        <Typography variant='caption'>Al cambiar de estado puedes dejar una nota con fecha</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            placeholder='Nota (opcional)'
                                            fullWidth
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            value={date}
                                            onChange={val => setDate(val)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} container justifyContent={'flex-end'}>
                                        <Button onClick={onSubmitStatus}>CONTINUAR</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </ModalContainer>
                </Modal>
                <Modal open={modalSubNote}>
                    <ModalContainer>
                        <Card>
                            <Box padding={3}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Crea una nota</Typography>
                                        <Typography variant='caption'>Al cambiar de estado puedes dejar una nota con fecha</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            placeholder='Nota (opcional)'
                                            fullWidth
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            value={date}
                                            onChange={val => setDate(val)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} container justifyContent={'flex-end'}>
                                        <Button onClick={onSubmitSubStatus}>CONTINUAR</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </ModalContainer>
                </Modal>
            </ListItemRoot>
        </Root >
    )
}

export default CardStatus