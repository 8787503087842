import React, { useEffect, useState } from 'react'
import { ListItemHead, ListItemRoot, ListItemTitle, Root, RootButtonAdd } from './ListCompany.style'
import { Avatar, CircularProgress, Typography } from '@mui/material'
import Credentials from './Credentials/Credentials'
import { Link, useSearchParams } from 'react-router-dom'
import getOneByCustomer, { ResponseData } from '@/api/company/getOneByCustomer'
import historyCompany from '@/api/companyProduct/historyCompany'
import NoteStatus from '@/components/StatusProduct/NoteStatus/NoteStatus'
import CardStatus from '@/components/StatusProduct/CardStatus/CardStatus'
import getOne from '@/api/companyProduct/getOne'
import Card from '@/components/Cards/Card/Card'

const ListCompany = () => {
    const [list, setList] = useState(null)
    const [load, setLoad] = useState(false)
    const [searchParams] = useSearchParams();

    const id = searchParams.get('id')
    const obtainCompanies = async () => {
        setLoad(true)
        const res = await getOneByCustomer(id)
        setLoad(false)
        console.log("getOneByCustomer", res)
        if (!res) {
            return alert('Error al obtener cliente')
        }
        setList(res.data)
    }

    useEffect(() => {
        void obtainCompanies()
    }, [])

    return (
        <Root>
            {
                load
                    ? <Card><CircularProgress /></Card>
                : list
                    ? list.length > 0
                        ? list.map((item: ResponseData) => (
                            <ListItem
                                to={`/company_perfil?id=${item.id}`}
                                item={item}
                            />
                        ))
                        : <Card><Typography color="gray">No tiene empresa</Typography></Card>
                    :<Card><CircularProgress /></Card>
            }
            <RootButtonAdd>
                <Link to={`/create_company?id=${id}`}>
                    <Typography>Agregar Empresa</Typography>
                </Link>
            </RootButtonAdd>
        </Root>
    )
}

const ListItem = ({ to, item }) => {
    const [historyLast, setHistoryLast] = useState(null)
    const [companyProduct, setCompanyProduct] = useState(null)

    const obtainLastHistory = async () => {
        const res = await historyCompany(item.id)
        if (!res) {
            return console.log("Error al obtner history")
        }
        setHistoryLast(res.data)
    }
    useEffect(() => {
        void obtainLastHistory()
    }, [item])

    const obtainProduct = async () => {
        const res = await getOne(historyLast.productId)
        if (!res) {
            return alert('Error al obtener producto')
        }
        setCompanyProduct(res.data)
    }

    useEffect(() => {
        if (historyLast) {
            void obtainProduct()
        }
    }, [historyLast])

    return (
        <ListItemRoot>
            <ListItemHead>
                <Avatar
                    src={item.img}
                    style={{ width: 66, height: 66 }} />
                <ListItemTitle>
                    <Link to={to}><Typography><b>{item.title}</b></Typography></Link>
                    <Typography>{companyProduct?.title}</Typography>
                    {companyProduct && <CardStatus
                        status={companyProduct.productStatus}
                        subStatus={companyProduct.productSubStatus}
                        companyProductId={companyProduct.id}
                        onChange={() => obtainLastHistory()}
                    />}
                </ListItemTitle>
            </ListItemHead>
            {
                historyLast
                && historyLast.note &&
                <NoteStatus note={historyLast.note} date={historyLast.date} />
            }
            {/* <ListItemCredentials>
                <Credentials />
            </ListItemCredentials> */}
        </ListItemRoot>
    )
}

export default ListCompany