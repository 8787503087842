import styled from "@emotion/styled";

export const Root = styled.div`
`

export const ListItemHead = styled.div`
    display: flex;
    align-items: center;
`

export const ListItemTitle = styled.div`
    margin-left: 20px;
`

export const ListItemStates = styled.div`
    display: flex;
    margin-top: 20px;
    > div {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 20px;
   }
   > div:first-child {
        background-color: #189DEF;
   }
   > div:last-child {
        background-color: #FF9900;
        margin-left: 10px;
   }
`


export const ListItemCredentials = styled.div`
    margin-top: 20px;
`

export const RootButtonAdd = styled.div`
    margin-top: 50px;
    display: flex;
    justify-content: center;
    opacity: 0.6;
`