import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    transition: transform 0.4s, height 0.4s, border-radius 0.4s, box-shadow 1.5s;
`
export const rootContentStyle = css`
    position: absolute;
    transform: scale(0.8) translateX(92%) !important;
    /* margin-left:  100px; */
    box-shadow: -10px -10px 100px rgba(5, 39, 104, 0.2);
    border-radius: 30px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`