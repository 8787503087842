import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import React, { useEffect, useState } from 'react'
import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';
import { Root, RootForm } from './CreateCompanyProduct.style';
import create from '@/api/companyProduct/create';
import get from '@/api/product/get';
import { useSearchParams } from 'react-router-dom';
import getStatus from '@/api/productStatus/get'
import getSubStatus from '@/api/productSubStatus/get'

const CreateCompanyProduct = () => {
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [load, setLoad] = useState(false)
  const [listStatus, setListStatus] = useState([])
  const [listSubStatus, setListSubStatus] = useState([])
  const [listProduct, setListProduct] = useState([])
  const [title, setTitle] = useState(null)
  const [product, setProduct] = useState(null)
  const [productStatus, setProductStatus] = useState(null)
  const [productSubStatus, setProductSubStatus] = useState(null)
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const filter = listSubStatus.filter(item => item.productStatus.id === productStatus)
    if (filter.length > 0) {
      setProductSubStatus(filter[0].id)
    }
  }, [productStatus])

  const obtainStatus = async () => {
    const res = await getStatus()
    if (!res) {
      return console.log('Error al obtener estados')
    }
    setListStatus(res.data)
  }
  const obtainSubStatus = async () => {
    const res = await getSubStatus()
    if (!res) {
      return console.log('Error al obtener sub estados')
    }
    setListSubStatus(res.data)
  }

  useEffect(() => {
    obtainStatus()
    obtainSubStatus()
  }, [])

  const obtainProducts = async () => {
    const res = await get()
    console.log("obtainProducts", res)
    if (!res) {
      setError('Error al obtener productos')
    }
    setListProduct(res.data)
  }

  useEffect(() => {
    void obtainProducts()
  }, [])

  const createProduct = async () => {
    setError('')
    if (!product) {
      return setError('Debes seleccionar un producto')
    }
    if (!productStatus) {
      return setError('Debes seleccionar un estado')
    }
    if (!productSubStatus) {
      return setError('Debes seleccionar un sub estado')
    }
    setLoad(true)
    const res = await create({
      title,
      product,
      company: searchParams.get('id'),
      productStatus,
      productSubStatus
    })
    setLoad(false)
    if (!res) {
      return setError('Error al crear producto')
    }
    empityState()
    setSuccess('¡Producto creado con exito!')
  }
  const empityState = () => {
    setTitle(null)
    setProduct(null)
    setProductStatus(null)
    setProductSubStatus(null)
  }
  return (
    <Root>
      <Snackbar
        open={Boolean(success)}
        autoHideDuration={6000}
        onClose={() => setSuccess('')}
        message={success}
      />
      <MenuHeader
        goBack
        right={() => { }}
        title='Agregar Producto'
      />
      <RootForm>
        <Card>
          <Grid style={{ padding: 20 }} container spacing={4} alignItems={'center'}>
            <Grid item xs={12}>
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder='Nombre | www.ejemplo.cl'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="product-select">Selecciona un producto</InputLabel>
                <Select
                  labelId="product-select"
                  id="product-select"
                  label="Age"
                  value={product}
                  onChange={e => setProduct(e.target.value)}
                >
                  {listProduct.map(item => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="product-select">Selecciona un estado</InputLabel>
                <Select
                  labelId="product-select"
                  id="product-select"
                  value={productStatus}
                  onChange={e => setProductStatus(e.target.value)}
                >
                  {listStatus.map(item => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {
              productStatus
              && <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="product-select" shrink>Selecciona un Sub estado</InputLabel>
                  <Select
                    labelId="product-select"
                    id="product-select"
                    value={productSubStatus}
                    onChange={e => setProductSubStatus(e.target.value)}
                  >
                    {listSubStatus
                      .filter(item => item.productStatus.id === productStatus)
                      .map(item => (
                        <MenuItem value={item.id}>{item.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            }
            <Grid item xs={12}>
              <Button
                onClick={createProduct}
                style={{ width: '100%' }}
                variant='contained'>AGREGAR</Button>
              {error && <Typography variant='body1' color="red">{error}</Typography>}
            </Grid>
          </Grid>
        </Card>
      </RootForm>
    </Root>
  )
}

export default CreateCompanyProduct