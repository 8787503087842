import React, { useEffect, useState } from 'react'
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import CreateIcon from '@mui/icons-material/Create';
import { Root, RootListCompany } from './ProductPerfil.style';
import Header from './Header/Header';
import getOne, { ResponseData } from '@/api/companyProduct/getOne';
import { Link, useSearchParams } from 'react-router-dom';
import CardStatus from '@/components/StatusProduct/CardStatus/CardStatus';
import history from '@/api/companyProduct/history'
import NoteStatus from '@/components/StatusProduct/NoteStatus/NoteStatus';
import Card from '@/components/Cards/Card/Card';

const ProductPerfil = () => {
    const [load, setLoad] = useState(false)
    const [companyProduct, setCompanyProduct] = useState<null | ResponseData>(null)
    const [historyLast, setHistoryLast] = useState(null)
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')

    const obtainAll = () => {
        void obtainLastHistory()
        void obtainProduct()
    }

    useEffect(() => {
        void obtainAll()
    }, [])

    const obtainProduct = async () => {
        setLoad(true)
        const res = await getOne(id)
        setLoad(false)
        if (!res) {
            return alert('Error al obtener producto')
        }
        setCompanyProduct(res.data)
    }

    const obtainLastHistory = async () => {
        const res = await history(id)
        if (!res) {
            return console.log("Error al obtner history")
        }
        setHistoryLast(res.data)
    }

    return (
        <Root>
            <MenuHeader
                goBack
                right={
                    <Link to={`/edit_product?id=${companyProduct && companyProduct.id}`}>
                        <CreateIcon style={{ color: 'white' }} />
                    </Link>
                }
                goBackColor={'white'}
            />
            <Header data={companyProduct} load={load} />
            <RootListCompany>
                <Card>
                    {companyProduct?.productStatus &&
                        <CardStatus
                            status={companyProduct.productStatus}
                            subStatus={companyProduct.productSubStatus}
                            companyProductId={companyProduct.id}
                            onChange={() => void obtainAll()}
                        />}
                    {
                        historyLast
                        && historyLast.note &&
                        <NoteStatus note={historyLast.note} date={historyLast.date} />
                    }
                </Card>
            </RootListCompany>
        </Root>
    )
}

export default ProductPerfil