import styled from "@emotion/styled";

export const Root = styled.div`
`

export const RootCard = styled.div`
    width: 93%;
    margin: auto;
    margin-top: -20px;
`
