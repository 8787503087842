import React, { useState } from 'react'
import BottomBar from '@/layout/BottomBar/BottomBar'
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import WelcomeCard from '@/modules/home/widgets/WelcomeCard/WelcomeCard'
import { Root, RootFloatButton } from './Client style'
import { List } from './List/List'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';

const Client = () => {
  const [activeMenu, setActiveMenu] = useState(false)
  return (
    <Root>
      <MenuHeader
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu} />
<FlotaingButton />
      <List />

      <BottomBar path='client' active={activeMenu} />
    </Root>
  )
}


const FlotaingButton = () => {
  return (
      <Link to="/create_pre_client">
          <RootFloatButton>
              <AddIcon style={{ color: 'white' }} />
          </RootFloatButton>
      </Link>
  )
}

export default Client