import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
display: flex;
    margin-top: 20px;
    > div:first-child {
        width: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    > div:last-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
`

export const verticalStyle = css`
       flex-direction: column-reverse;
       justify-content: flex-start !important;
       align-items: flex-start !important;
`
