import styled from "@emotion/styled";

const backgroungImage = require('@/assets/img/background/main.png')

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
`
export const RootHeader = styled.div`
    display: flex;
    height: 50vh;
    width: 100%;
    background-image: url(${backgroungImage});
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`
export const LogoHeader = styled.img`
    width: 264px;
    object-fit: contain;
`
export const RootCard = styled.div`
    background-color: white;
    border-radius: 40px;
    margin-top: -10vh;
    padding-top: 60px;
    padding-left: 10px;
    padding-right: 10px;
    height: 60vh;
    display: flex;
    flex-direction: column;
`

export const RootForm = styled.div`
    width: 100%;
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`