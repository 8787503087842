import { Box, Button, Card, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ListModalRoot, RootBody, RootButtons, RootTitle } from './ListModal.style'
import CardItem from '@/components/Cards/CardItem/CardItem'
import get, { ResponseData } from '@/api/taskList/get'
import AddIcon from '@mui/icons-material/Add';
import create from '@/api/taskList/create'

const ListModal = ({ value, setValue, open, setOpen, setListName }) => {
    const [list, setList] = useState<ResponseData[]>([])
    const [nameNewList, setNameNewList] = useState(null)

    const getTaskList = async () => {
        const res = await get()
        if (!res) {
            return console.log('Error al obtener listas')
        }
        setList(res.data)
    }

    useEffect(() => {
        void getTaskList()
    }, [])

    const createTaskList = async () => {
        const res = await create({title: nameNewList})
        console.log("create",res)
        if (!res) {
            return console.log('Error al obtener listas')
        }
        onSubmit(res.data.id, res.data.title)
    }

    const onSubmit = (id, name) => {
        setValue(id)
        setListName(name)
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <ListModalRoot>
                <Card>
                    <Box padding={4}>
                        <RootTitle>
                            <Typography variant='h6' style={{ marginBottom: 50 }}>Lista</Typography>
                            <div>
                                <TextField 
                                    variant='standard'
                                    placeholder='Nombre lista nueva'
                                    value={nameNewList}
                                    onChange={e => setNameNewList(e.target.value)}
                                />
                            </div>
                        </RootTitle>
                        <RootBody>
                            {
                                list.map(item => (
                                    <div style={{ marginBottom: 20 }}>
                                        <ListItem
                                            onClick={()=> onSubmit(item.id, item.title)}
                                            item={item} />
                                    </div>
                                ))
                            }
                        </RootBody>
                        <RootButtons>
                            <Button onClick={() => setOpen(false)}>Cerrar</Button>
                            <Button disabled={!nameNewList} onClick={() => nameNewList && createTaskList()}>Crear</Button>
                        </RootButtons>
                    </Box>
                </Card>
            </ListModalRoot>
        </Modal>
    )
}

const ListItem = ({ item, onClick }: any) => {
    return (
        <div onClick={onClick}>
            <CardItem title={item.title} />
        </div>
    )
}

export default ListModal