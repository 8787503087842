import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import React from 'react'

const EditTask = () => {
    return (
        <div>
            <MenuHeader
                goBack
                goBackTo={'/task'}
                right={() => { }}
                title='Tarea'
            />
        </div>
    )
}

export default EditTask