import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './layout/Layout'
import Login from './pages/login/Login'
import Protected from './layout/Protected/Protected'
import Home from './pages/home'
import Client from './pages/client'
import CreateClient from '@/pages/create_client/CreateClient';
import ClientPerfil from '@/pages/client_perfil/ClientPerfil';
import Task from '@/pages/task/Task';
import CreateTask from '@/pages/create_task/CreateTask';
import TaskList from '@/pages/task_list/TaskList';
import EditTask from '@/pages/edit_task/EditTask';
import CreateUser from '@/pages/create_user/CreateUser';
import CompanyPerfil from '@/pages/company_perfil/CompanyPerfil';
import ProductPerfil from '@/pages/product_perfil/ProductPerfil';
import CreateCompanyProduct from '@/pages/create_company_product/CreateCompanyProduct';
import EditClient from '@/pages/edit_client/EditClient';
import EditProduct from './pages/edit_product/EditProduct'
import EditCompany from './pages/edit_company/EditCompany'
import CreateCompany from './pages/client_perfil/create_company/CreateCompany'
import CreatePreClient from './pages/create_pre_client/CreatePreClient'
import PreClient from './pages/pre_client/PreClient'
import PreClientPerfil from './pages/pre_client_perfil/PreClientPerfil'
import EditPreClient from './pages/edit_pre_client /EditPreClient'

const App = () => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route index element={<Login />} />
                <Route element={<Protected isLoggedIn={true} />}>
                    <Route path='home' element={<Home />} />
                    <Route path='client' element={<Client />} />
                    <Route path='create_client' element={<CreateClient />} />
                    <Route path='pre_client' element={<PreClient />} />
                    <Route path='create_pre_client' element={<CreatePreClient />} />
                    <Route path='pre_client_perfil' element={<PreClientPerfil />} />
                    <Route path='edit_pre_client' element={<EditPreClient />} />
                    <Route path='client_perfil' element={<ClientPerfil />} />
                    <Route path='create_company' element={<CreateCompany />} />
                    <Route path='create_user' element={<CreateUser />} />
                    <Route path='task' element={<Task />} />
                    <Route path='create_task' element={<CreateTask />} />
                    <Route path='task_list' element={<TaskList />} />
                    <Route path='edit_task' element={<EditTask />} />
                    <Route path='company_perfil' element={<CompanyPerfil />} />
                    <Route path='product_perfil' element={<ProductPerfil />} />
                    <Route path='create_company_product' element={<CreateCompanyProduct />} />
                    <Route path='edit_client' element={<EditClient />} />
                    <Route path='edit_company' element={<EditCompany />} />
                    <Route path='edit_product' element={<EditProduct />} />
                </Route>
                <Route path="*" element={<p>No hay nada aquí: 404!</p>}/>
            </Route>
        </Routes>
    )
}

export default App

/*
{
      path: "/task",
      element: <Task />,
    },
    {
      path: "/create_task",
      element: <CreateTask />,
    },
    {
      path: "/client_perfil",
      element: <ClientPerfil />,
    },
    {
      path: "/company_perfil",
      element: <CompanyPerfil />,
    },
    {
      path: "/product_perfil",
      element: <ProductPerfil />,
    },
    {
      path: "/create_client",
      element: <CreateClient />,
    },
    {
      path: "/task-list",
      element: <TaskList />,
    },
    {
      path: "/edit_task",
      element: <EditTask />,
    },
    {
      path: "/create_user",
      element: <CreateUser />,
    },
    {
      path: "/create_product",
      element: <CreateCompanyProduct />,
    },
*/