import React, { useEffect, useState } from 'react'
import { Root, RootBody, RootButtons, RootTitle } from './ClientModal.style'
import { Box, Button, Card, Modal, TextField, Typography } from '@mui/material'
import get, { ResponseData } from '@/api/customer/get'
import CardItem from '@/components/Cards/CardItem/CardItem'

const ClientModal = ({ value, setValue, setName, open, setOpen }) => {
    const [list, setList] = useState<ResponseData[]>([])
    console.log("list", list)
    const getCustomers = async () => {
        const res = await get()
        if (!res) {
            return console.log('Error al obtener clientes')
        }
        setList(res.data)
    }

    useEffect(() => {
        void getCustomers()
    }, [])

    const onSubmit = (id, name) => {
        setValue(id)
        setName(name)
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Root>
                <Card>
                    <Box padding={3}>
                        <RootTitle>
                            <Typography variant='h6' style={{ marginBottom: 50 }}>Cliente</Typography>
                            <TextField
                                variant='standard'
                                placeholder='Buscar...'
                            />
                        </RootTitle>
                        <RootBody>
                            {
                                list.map(item => (
                                    <div style={{ marginBottom: 20 }}>
                                        <ListItem
                                            onClick={() => onSubmit(item.id, item.first_name + ' ' + item.last_name)}
                                            item={item} />
                                    </div>
                                ))
                            }
                        </RootBody>
                        <RootButtons>
                            <Button onClick={() => setOpen(false)}>Cerrar</Button>
                        </RootButtons>
                    </Box>
                </Card>
            </Root>
        </Modal>
    )
}

const ListItem = ({ item, onClick }) => {
    return (
        <div onClick={onClick}>
            <CardItem
                avatar={item.img}
                title={item.first_name + ' ' + item.last_name} />
        </div>
    )
}
export default ClientModal