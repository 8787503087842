import styled from '@emotion/styled'
import React from 'react'

const CircleOrange = () => {
  return (
    <Root />
  )
}

export const Root = styled.div`
    background-color: #FF9900;
    width: 10px;
    height: 10px;
    border-radius:50px;
`

export default CircleOrange