import styled from "@emotion/styled";

export const Root = styled.div`
    background-color: white;
    border-radius: 11px;
    background: #FFF;
    box-shadow: 0px 8px 14px 0px rgba(174, 192, 203, 0.42);
    padding: 20px;
    margin-bottom: 30px;
`
