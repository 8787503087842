import styled from "@emotion/styled";

const cardMain = require('@/assets/img/background/card-main.png')

export const Root = styled.div`
    background-image: url(${cardMain});
    width: 93%;
    margin: auto;
    border-radius: 21px;
    box-shadow: 0px 8px 14px 0px rgba(174, 192, 203, 0.42);
    > div {
        padding: 20px;
    }
`
