import styled from "@emotion/styled";

export const Root = styled.div`
    width: 100%;
    height: 49px;
    border-radius: 11px;
    background: #FFF;
    box-shadow: 0px 8px 14px 0px rgba(174, 192, 203, 0.42);
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const RootTitle = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
`

export const RootRight = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
`