import React, { useEffect, useState } from 'react'
import { ListItemHead, ListItemTitle, Root, RootButtonAdd } from './ListCompany.style'
import { Avatar, CircularProgress, Typography } from '@mui/material'
import Credentials from './Credentials/Credentials'
import { Link, useSearchParams } from 'react-router-dom'
import history from '@/api/companyProduct/history'
import CardStatus from '@/components/StatusProduct/CardStatus/CardStatus'
import NoteStatus from '@/components/StatusProduct/NoteStatus/NoteStatus'
import Card from '@/components/Cards/Card/Card'

const ListCompany = ({ list, obtainAll, load }) => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    return (
        <Root>
            {
                load
                    ? <Card>
                        <CircularProgress />
                    </Card>
                    : list 
                        ? list.length > 0
                            ? list.map(item => (
                                <ListItem
                                    to={`/product_perfil?id=${item.id}`}
                                    item={item}
                                    obtainAll={obtainAll}
                                />)) 
                            : <Card><Typography color="gray">No tiene productos</Typography></Card>
                        : <Card><CircularProgress /></Card>
            }
            <RootButtonAdd>
                {list && <Link to={`/create_company_product?id=${id}`}>
                    <Typography>Agregar producto</Typography>
                </Link>}
            </RootButtonAdd>
        </Root>
    )
}

const ListItem = ({ to, item, obtainAll }) => {
    const [historyLast, setHistoryLast] = useState(null)

    const obtainLastHistory = async () => {
        const res = await history(item.id)
        if (!res) {
            return console.log("Error al obtener history")
        }
        setHistoryLast(res.data)
    }
    useEffect(() => {
        void obtainLastHistory()
    }, [item])

    return (
        <Card>
            <ListItemHead>
                <Avatar src={item.img} style={{ width: 66, height: 66 }} />
                <ListItemTitle>
                    <Link to={to}>
                        <Typography variant='subtitle1'>{item.product.title}</Typography></Link>
                    <Typography variant='body2'>{item.title}</Typography>
                    {item?.productStatus && <CardStatus
                        status={item.productStatus}
                        subStatus={item.productSubStatus}
                        companyProductId={item.id}
                        onChange={() => obtainAll()}
                    />}
                </ListItemTitle>
            </ListItemHead>
            {
                historyLast
                && historyLast.note &&
                <NoteStatus note={historyLast.note} date={historyLast.date} />
            }
            {/* <ListItemCredentials>
                <Credentials />
            </ListItemCredentials> */}
        </Card>
    )
}

export default ListCompany