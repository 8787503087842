import { createTheme } from "@mui/material";

export const maxSizeMackbookNum = 1723
export const maxSizeTabletNum = 1024
export const maxSizeMobileNum = 414

export const maxSizeMackbook = maxSizeMackbookNum + 'px'
export const maxSizeTablet = maxSizeTabletNum + 'px'
export const maxSizeMobile = maxSizeMobileNum + 'px'
 
export const theme = createTheme({
    typography: {
      fontFamily: [
        'Lato',
        'Prompt',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(',')
    },
    palette: {
      primary:  {
        main: '#189DEFFC',
        dark: '#343540'
      },
      background: {
        default: '#06223B'
      }
    }
  });
  
theme.typography.h1 = {
  fontFamily: 'Poppins',
  fontSize: 70,
  fontWeight: 700,
  letterSpacing: -3,
  color: theme.palette.primary.main
}
theme.typography.h2 = {
  fontFamily: 'Poppins',
  fontSize: 50,
  fontWeight: 700,
  color: theme.palette.primary.main
}
theme.typography.h3 = {
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: 53,
  color: theme.palette.primary.main,
  lineHeight: 1.15,
}
theme.typography.h4 = {
  fontFamily: 'Lato',
  fontSize: 28,
  fontWeight: 500,
  color: theme.palette.primary.dark
}
  
theme.typography.h5 = {
  fontFamily: 'Lato',
  fontSize: 26,
  fontWeight:'normal',
  color: theme.palette.primary.dark
}
  
theme.typography.h6 = {
  fontFamily: 'Lato',
  fontSize: 22,
  fontWeight: '600',
  color: theme.palette.primary.dark
}
  
  
theme.typography.subtitle1 = {
  fontFamily: 'Lato',
  fontSize: 16,
  fontWeight:'900',

  color: theme.palette.primary.dark
}
  
theme.typography.subtitle2 = {
  fontFamily: 'Lato',
  fontSize: 24,
  fontWeight:'initial',
  color: theme.palette.primary.dark
}
  
theme.typography.caption = {
  fontSize: 12,
  letterSpacing:1.2,
  color: theme.palette.primary.dark
}
  
theme.typography.body1 = {
  fontSize: 16,
  color: theme.palette.primary.dark
}