import styled from "@emotion/styled";

export const Root = styled.div`
`
export const ListItemRoot = styled.div`
    margin-bottom: 21px;
    margin-left: 11px;
    margin-right: 11px;
    padding: 20px 15px;
    box-shadow: 0px 8px 14px rgba(174, 192, 203, 0.42);
    background-color: white;
    border-radius: 11px;
`
export const ListItemHead = styled.div`
    display: flex;
    height: 120px;
    > div:first-child {
        width: 100px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
`
export const ListItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80%;
`
export const ListItemContainerState = styled.div`
    display: flex;
    > div:first-child {
        width: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const ListItemFooter = styled.div`
   display: flex;
   > div {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 20px;
   }
   > div:first-child {
        background-color: #189DEF;
   }
   > div:last-child {
        background-color: #FF9900;
        margin-left: 10px;
   }
`

export const RootLoad = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
`
