import React from 'react'
import { MenuItemRoot, MenuListRoot, Root, RootCard, RootContainer, RootIcon, menuListActive, rootActive } from './BottomBar.style'
import HomeIcon from '@mui/icons-material/Home';
import ChecklistIcon from '@mui/icons-material/Checklist';
import GroupIcon from '@mui/icons-material/Group';
import { Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@/provider/AuthProvider';

interface Props {
    active: boolean
    path: string
}

const BottomBar: React.FC<Props> = ({
    active,
    path
}) => {
    return (
        <Root className={active ? rootActive : null}>
            <MenuList active={active} />
            <RootCard>
                <RootContainer>
                    <Link to={'/task'}>
                        <RootIcon>
                            <ChecklistIcon style={{ fontSize: 45, color: path === 'task' ? '#189DEF' : '#D8E2E8' }} />
                        </RootIcon>
                    </Link>
                    <Link to={'/home'}>
                        <RootIcon>
                            <HomeIcon style={{ fontSize: 45, color: path === 'home' ? '#189DEF' : '#D8E2E8' }} />
                        </RootIcon>
                    </Link>
                    <Link to={'/client'}>
                        <RootIcon>
                            <GroupIcon style={{ fontSize: 45, color: path === 'client' ? '#189DEF' : '#D8E2E8' }} />
                        </RootIcon>
                    </Link>
                </RootContainer>
            </RootCard>
        </Root>
    )
}


const MenuList = ({ active }) => {
    const navigate = useNavigate()
    const auth = useAuth()

    const logOut = () => {
        auth.signout(() => {
            navigate('/')
        })
    }
    return (
        <MenuListRoot className={active ? menuListActive : null}>
            <div>
                {/* <MenuItem title="Perfil" />
                <MenuItem title="Notificaciones" />
                <MenuItem title="Productos" />
                <MenuItem title="Estados" /> */}
                <MenuItem title="Posibles clientes" to="/pre_client" />
                <MenuItem title="Crear usuario" to="/create_user" />
            </div>
            <div>
                <MenuItem title="Ajustes" />
                <MenuItem title="Cerrar sesión" onClick={logOut}/>
            </div>
        </MenuListRoot>
    )
}


const MenuItem = ({ title, to, onClick }: any) => {
    const navigate = useNavigate()
    const onClickNavigate = () => {
        navigate(to)
    }

    return (
        <div onClick={onClick ? onClick : onClickNavigate}>
            <MenuItemRoot>
                <Typography variant='subtitle1'>{title}</Typography>
            </MenuItemRoot>
        </div>
    )
}


export default BottomBar