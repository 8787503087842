import React, { useState } from 'react'
import BottomBar from '@/layout/BottomBar/BottomBar'
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import WelcomeCard from '@/modules/home/widgets/WelcomeCard/WelcomeCard'
import { Root } from './Task.style'
import Header from '@/modules/task/Header/Header'

const Task = () => {
  const [activeMenu, setActiveMenu] = useState(false)
  return (
    <Root>
      <MenuHeader
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu} />

      <Header />

      <BottomBar path='task' active={activeMenu} />
    </Root>
  )
}

export default Task