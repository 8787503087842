import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import React, { useRef, useState } from 'react'
import { Avatar, Button, Card, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Root, RootForm } from './CreateCompany.style';
import uploadImg from '@/api/company/uploadImg';
import create from '@/api/company/create';

const CreateCompany = () => {
    const [error, setError] = useState(null)
    const [load, setLoad] = useState(false)
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState(null)
    const [img, setImg] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [instagram, setInstagram] = useState(null)
    const [searchParams] = useSearchParams();

    const createCompany = async () => {
        const id = searchParams.get('id')
        setError('')
        let req: any = {
            title,
            email,
            phone,
            instagram,
            customer: id
        }
        if (img) {
            const res = await uploadImageUrl(img)
            if (!res) { return null }
            req.img = res
        }
        setLoad(true)
        const res = await create(req)
        console.log("update", res)
        setLoad(false)
        if (!res) {
            return setError('Error al crear')
        }
        setMessage('Empresa creada con éxito!')
        empityState()
    }

    const fileInput = useRef(null)
    const empityState = () => {
      fileInput.current.value = ''
      setImg(null)
      setPhone(null)
      setEmail(null)
      setInstagram(null)
    }

    const uploadImageUrl = async (objectImage) => {
        setLoad(true)
        const res = await uploadImg(objectImage)
        setLoad(false)
        if (!res) {
            setError('Error al cargar imagen Empresa')
            return null
        }
        if (!res.data) {
            if (res.error) {
                setError(res.error.errorMessage)
                return null
            } else {
                setError('Error al cargar imagen Empresa')
                return null
            }
        }
        return res.data.url
    }

    const uploadFileImg = event => {
        if (event.target.files && event.target.files[0]) {
            const i = event.target.files[0]
            setImg(i)
        }
    }

    const onSubmit = () => {
        setError('')
        createCompany()
    }

    return (
        <Root>
            <MenuHeader
                goBack
                right={() => { }}
                title='Agregar Empresa'
            />
            <RootForm>
                <Card>
                    <Grid style={{
                        padding: 20
                    }} container spacing={4} alignItems={'center'}>
                        <Grid item xs={12}>
                            <Typography>Foto Empresa</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Avatar
                                src={img && URL.createObjectURL(img) ? URL.createObjectURL(img) : null}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <input
                                ref={fileInput}
                                onChange={uploadFileImg}
                                type='file' />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder='Título'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Email'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder='Teléfono'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={instagram}
                                onChange={(e) => setInstagram(e.target.value)}
                                placeholder='@ Instagram'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{ width: '100%' }}
                                variant='contained'
                                onClick={() => onSubmit()}
                            >EDITAR</Button>
                        </Grid>
                    </Grid>
                </Card>
            </RootForm>
            <Snackbar
                open={Boolean(message)}
                autoHideDuration={6000}
                onClose={() => setMessage('')}
                message={message}
            />
        </Root>
    )
}

export default CreateCompany