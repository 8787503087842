import errorLog from '@/utils/errorLog'
import Axios from 'axios'
import config from '@/config';

// eslint-disable-next-line import/no-anonymous-default-export
export default async (id): Promise<Response | null> => {
    const path = `/company-product/company/${id}`
    const url = config.API_URL + path
    const headers = { ...config.APIHEADER }

    return Axios.get(url, { headers })
        .then(response => {
            return response.data
        })
        .catch(error => {
            errorLog({ path, url, req: null, error })
            return null
        })
}

interface Response {
    data: ResponseData[]
    error: {
        errorCode: number
        errorMessage: string
    }
}

export interface ResponseData {
    id: number
    first_name: string
    last_name: string
    phone: string
    email: string
    img: string
    instagram: string
    customerType: ResponseDataCustomerType
    customerStatus: ResponseDataCustomerStatus
    customerSubStatus: ResponseDataCustomerSubStatus
    company: ResponseDataCompany[]
}

interface ResponseDataCustomerType {
    id: number
    title: string
    description: string
}

interface ResponseDataCustomerStatus {
    id: number
    title: string
    description: string
}

interface ResponseDataCustomerSubStatus {
    id: number
    title: string
    description: string
}

interface ResponseDataCompany {
    id: number
    title: string
    img: string
    create_date: string
}
