import Axios from 'axios'
import config from '@/config';
import errorLog from '@/utils/errorLog'

interface Request {
    id: any;
    productStatus: any;
    productSubStatus?: any;
    note?: any;
    date?: any;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default async (req: Request): Promise<Response | null> => {
    const path = `/company-product/status`
    const url = config.API_URL + path
    const headers = { ...config.APIHEADER }
    const body = req
    return Axios.put(url, body, { headers })
        .then(response => {
            return response.data
        })
        .catch(error => {
            errorLog({ path, url, req: null, error })
            return null
        })
}

interface Response {
    data: any
    error: {
        errorCode: number
        errorMessage: string
    }
}