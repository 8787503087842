import React from 'react'
import { Root, RootRight, RootTitle } from './CardItem.style'
import { Avatar, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {
  title: string
  rightElement?:any
  avatar?:string
}

const CardItem:React.FC<Props> = ({ title, rightElement, avatar }) => {
  return (
    <Root>
      <RootTitle>
        {avatar && <Avatar src={avatar} style={{marginRight:10}} />}
        <Typography variant='body1'>{title}</Typography>
      </RootTitle>
      <RootRight>
        {rightElement}
        <KeyboardArrowRightIcon />
      </RootRight>
    </Root>
  )
}

export default CardItem