import React, { useState } from 'react'
import BottomBar from '@/layout/BottomBar/BottomBar'
import { Root } from './Home.style'
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import WelcomeCard from '@/modules/home/widgets/WelcomeCard/WelcomeCard'
import { useAuth } from '@/provider/AuthProvider'

const Home = () => {
  const [activeMenu, setActiveMenu] = useState(false)
  const auth = useAuth()
  console.log("auth", auth.user)
  return (
    <Root>
      <MenuHeader
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu} />

      <WelcomeCard />

      <BottomBar path='home' active={activeMenu} />
    </Root>
  )
}

export default Home