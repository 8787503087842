import Axios from 'axios';
import config from '@/config';
import errorLog from '@/utils/errorLog';

// eslint-disable-next-line import/no-anonymous-default-export
export default async (objectImage): Promise<Response | null> => {
    const path = '/user/img'
    const url = config.API_URL + path
    const body = new FormData()
    body.append('image', objectImage)
    const headers = { ...config.APIHEADER }

    return Axios.post(url, body, { headers })
        .then(response => response.data)
        .catch(error => {
            errorLog({ path, url, req: null, error })
            return null
        })
}

interface Response {
    data: ResponseData
    error: {
        errorCode: number
        errorMessage: string
    }
}

interface ResponseData {
    filename: string
    originalname: string
    url: string
}
